import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StepsService {

  public id = 1;

  constructor() { }

}
