import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { SearchCountryField, TooltipLabel, CountryISO } from 'ngx-intl-tel-input';
import { DOCUMENTTYPE, DOCUMENTMASK, DOCUMENTNUMBER } from '../models/document';
import { ApiService } from '../service/api.service';
import { routerTransition } from '../@core/animations/animationRouter';
import { PlansService } from '../service/plans.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { UserService } from '../service/user.service';
import { StepsService } from '../service/steps.service';

@Component({
  selector: 'app-form-profile',
  animations: [
    routerTransition()
  ],
  templateUrl: './form-profile.component.html',
  styleUrls: ['./form-profile.component.scss']
})
export class FormProfileComponent implements OnInit {

  loading = false;
  menssage = '';
  formUser: FormGroup;
  titleDoc = 'CPF';
  maskDoc = '000.000.000-00';

  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  TooltipLabel = TooltipLabel;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.Brazil, CountryISO.Chile, CountryISO.Argentina, CountryISO.UnitedStates];

  constructor(
    private fb: FormBuilder,
    private apiService: ApiService,
    private plansService: PlansService,
    private router: Router,
    private userService: UserService,
    private stepsService: StepsService
  ) {
    this.formUser = this.fb.group({
      email: ['h.suel17@hotmail.com', [Validators.required, Validators.email]],
      // tslint:disable-next-line: max-line-length
      password: ['@Teste123', [Validators.required, Validators.pattern(/(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z])(?=.*[!"#$%&'()*+,-./{}|~€‚ƒ„…†‡ˆ‰Š‹ŒŽ‘’“”•–—˜™š›œžŸ¡¢£¤¥¦§¨©ª«¬®¯°±²³´µ¶·¸¹º»¼½¾¿:;<>=?@[\]\\^_`~]).*$/)]],
      first_name: ['Henrique', [Validators.required, Validators.minLength(3), Validators.pattern(/^[A-Za-z ]+$/)]],
      last_name: ['Suel', [Validators.required, Validators.minLength(3), Validators.pattern(/^[A-Za-z ]+$/)]],
      phone: ['11958134196', [Validators.required]],
      msisdn: [''],
      birthdateFront: ['27/05/1996', [Validators.required]],
      birthdate: [''],
      /* gender: ['1', [Validators.required]], */
      document: this.fb.group({
        id: [1],
        number: ['46097891830', [Validators.required]]
      }),
      nationality: ['BRA', Validators.required],
      agreement: [true, Validators.required]
    });
  }

  ngOnInit() {
    environment.session_token = '';
    environment.step = '1';
  }


  changePreferredCountries() {
    this.preferredCountries = [CountryISO.India, CountryISO.Canada];
  }

  isFieldValid(field: string) {
    return !this.formUser.get(field)['valid'] && this.formUser.get(field)['touched'];
  }

  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }

  selectCountry() {
    const nationality = this.formUser.get('nationality').value;
    this.titleDoc = DOCUMENTTYPE[nationality];
    this.maskDoc = DOCUMENTMASK[nationality];
    this.formUser.get('document.id').setValue(DOCUMENTNUMBER[nationality]);
  }


  teste() {
    this.router.navigate([`${this.userService.language}/${environment.system}/plans`]);
  }
  onSubmit() {
    const agreement = this.formUser.get('agreement').value;
    if (agreement) {
      this.apiService.setAuthorization();
      this.loading = true;
      this.menssage = '';
      const phone = this.formUser.get('phone').value;
      console.log(`${phone.dialCode}${phone.number}`);
      console.log(`${phone.dialCode}${phone.phonenumber}`);
      this.formUser.get('msisdn').setValue(`${phone.dialCode}${phone.number}`);
      const birthdate = this.formUser.get('birthdateFront').value.replace(/([/])/g, '');
      this.formUser.get('birthdate').setValue(`${birthdate.substr(4, 4)}-${birthdate.substr(2, 2)}-${birthdate.substr(0, 2)}`);
      this.apiService.postAPI('partner/subscribe', this.formUser.value)
        .subscribe(resp => {
          const listPlans = resp.plans[0].plans;
          if (resp.plans.length > 1) {
            resp.plans[1].plans.forEach(plans => {
              listPlans.push(plans);
            });
          };
          this.plansService.listPlans = this.plansService.setPlans(listPlans);
          environment.session_token = resp.session_token;
          environment.step = resp.step.toString();
          this.loading = false;
          this.router.navigate([`${this.userService.language}/${environment.system}/plans`]);
        },
          e => {
            this.loading = false;
            const error = e['error']['error']['message'];
            if (error.length > 0) {
              this.menssage = error[0];
              environment.step = e.error.step.toString();
              environment.session_token = e.error.session.toString();
            }
          });
    } else {
      this.formUser.controls.agreement.setValue(null);
    }
  }

}
