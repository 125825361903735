import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserService } from '../service/user.service';
import { routerTransition } from '../@core/animations/animationRouter';
import { ApiService } from '../service/api.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-form-credit-card',
  animations: [
    routerTransition()
  ],
  templateUrl: './form-credit-card.component.html',
  styleUrls: ['./form-credit-card.component.scss']
})
export class FormCreditCardComponent implements OnInit {

  public plan = null;
  public menssage = '';
  formCard: FormGroup;
  loading = false;
  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private apiService: ApiService,
    private router: Router
  ) {
    this.formCard = this.fb.group({
      credit_card: this.fb.group({
        cc_number: ['4551880502962996', [Validators.required]],
        cc_owner_name: ['Mauricio Villar', [Validators.required]],
        expiration_date: ['', [Validators.required]],
        cc_security_code: ['450', [Validators.required]],
        cc_expiration_date: ['']
      }),
      plan: [this.userService.plans.id, Validators.required]
    });
  }

  ngOnInit() {
    this.plan = this.userService.plans;
  }

  onSubmit() {
    this.loading = true;
    this.apiService.setAuthorization();
    const expiration_date = this.formCard.get('credit_card.expiration_date').value;
    this.formCard.get('credit_card.cc_expiration_date').setValue(`${expiration_date.substr(2, 4)}-${expiration_date.substr(0, 2)}`);
    this.apiService.postAPI('partner/subscribe', this.formCard.value)
      .subscribe(resp => {
        this.loading = false;
        this.router.navigate([`${this.userService.language}/${environment.system}/confirmation`]);
      },
        e => {
          this.loading = false;
          const error = e['error']['error']['message'];
          if (error.length > 0) {
            this.menssage = error[0];
            environment.step = e.error.step.toString();
            environment.session_token = e.error.session.toString();
          }
        });
  }
}
