export enum DOCUMENTTYPE {
  BRA = 'CPF',
  ARG = 'DNI',
  CHL = 'RUT',
  USA = 'PASSAPORTE'
}
export enum DOCUMENTNUMBER {
  BRA = 1,
  ARG = 4,
  CHL = 3,
  USA = 2
}

export enum DOCUMENTMASK {
  BRA = '000.000.000-00',
  ARG = '',
  CHL = '',
  USA = ''
}
