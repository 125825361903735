import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from './user.service';
import { environment } from 'src/environments/environment';
import { PlansService } from './plans.service';
import { StepsService } from './steps.service';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {

  constructor(
    private router: Router,
    private userService: UserService,
    private plansService: PlansService,
    private stepsService: StepsService
  ) {
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | boolean {
    console.log(state, next);
    return true;
  }


  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    this.userService.language = next.params.idLanguage;
    environment.system = next.params.idSystem;

    let route = `${this.userService.language}/${environment.system}/profile`;
    const id = next.data.id;
    if (id > 1) {
      if (environment.session_token !== '') {
        if (id >= 2) {
          if (this.plansService.listPlans.length !== 0) {
            route = `${this.userService.language}/${environment.system}/plans`;
          }
          if (id >= 3) {
            if (this.userService.plans !== null) {
              route = `${this.userService.language}/${environment.system}/creditCard`;
            }
          }
          if (id >= 4) {
            route = `${this.userService.language}/${environment.system}/confirmation`;
          }
        }
      }
    }
    if (state.url !== '/' + route) {
      this.router.navigate([route]);
      return false;
    }
    this.stepsService.id = id;
    return true;
  }
}
