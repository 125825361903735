import { Component, OnInit } from '@angular/core';
import { ApiService } from '../service/api.service';
import { PlansService } from '../service/plans.service';
import { UserService } from '../service/user.service';
import { Router } from '@angular/router';
import { routerTransition } from '../@core/animations/animationRouter';
import { StepsService } from '../service/steps.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-form-plans',
    animations: [
    routerTransition()
  ],
  templateUrl: './form-plans.component.html',
  styleUrls: ['./form-plans.component.scss']
})
export class FormPlansComponent implements OnInit {

  constructor(
    private apiService: ApiService,
    private plansService: PlansService,
    private stepsService: StepsService,
    private userService: UserService,
    private router: Router
  ) { }

  ngOnInit() {
  }

  selectPlans(plans) {
    this.userService.plans = plans;
    this.router.navigate([`${this.userService.language}/${environment.system}/creditCard`]);
  }
}
