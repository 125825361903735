import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class PlansService {


  public listPlans = Array<any>();

  constructor(
    private apiService: ApiService,
  ) { }

  getPlans(id: number) {
    if (this.listPlans.length === 0) {
      this.apiService.getAPI(`/system/${id}/plans`)
        .subscribe(response => {
          if (response.length > 0) {
            const listPlans = response[0].plans;
            if (response.length > 1) {
              response[1].plans.forEach(plans => {
                listPlans.push(plans);
              });
            }
            this.listPlans = this.setPlans(listPlans);
          }
        },
          error => console.log(error));
    }
  }

  resetPlans() {
    this.listPlans = new Array<any>();
  }

  setPlans(listPlans: Array<any>) {
    const plans = listPlans.map(p => {
      return {
        id: p.id,
        description: p.short_description,
        price: p.value.price,
        priceSymbol: `${p.value.formated_price}`,
        title: `${p.name}`,
        class: '',
        voucher: '',
        priceVoucher: '',
      };
    });
    return plans;
  }

}
