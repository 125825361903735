import { Component, OnInit } from '@angular/core';
import { routerTransition } from '../@core/animations/animationRouter';

@Component({
  selector: 'app-form-confirmation',
  animations: [
    routerTransition()
  ],
  templateUrl: './form-confirmation.component.html',
  styleUrls: ['./form-confirmation.component.scss']
})
export class FormConfirmationComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
