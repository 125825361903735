import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FormProfileComponent } from './form-profile/form-profile.component';
import { FormPlansComponent } from './form-plans/form-plans.component';
import { FormCreditCardComponent } from './form-credit-card/form-credit-card.component';
import { FormConfirmationComponent } from './form-confirmation/form-confirmation.component';
import { AuthGuard } from './service/auth.guard';

const routes: Routes = [
  {
    path: ':idLanguage/:idSystem',
    children: [
      {
        path: '',
        component: FormProfileComponent,
        canActivate: [AuthGuard],
        data: {
          id: 1
        }
      },
      {
        path: 'profile',
        component: FormProfileComponent,
        canActivate: [AuthGuard],
        data: {
          id: 1
        }
      },
      {
        path: 'plans',
        component: FormPlansComponent,
        canActivate: [AuthGuard],
        data: {
          id: 2
        }
      },
      {
        path: 'creditCard',
        component: FormCreditCardComponent,
        canActivate: [AuthGuard],
        data: {
          id: 3
        }
      },
      {
        path: 'confirmation',
        component: FormConfirmationComponent,
        canActivate: [AuthGuard],
        data: {
          id: 4
        }
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
