import { Component } from '@angular/core';
import { StepsService } from './service/steps.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  title = 'form-shooga';

  constructor(
    public stepsService: StepsService,
    private translateService: TranslateService
  ) {
    this.setLanguage();
  }


  setLanguage() {
    console.log('chamou')
    this.translateService.setDefaultLang('pt');
  }
}
