import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { forkJoin } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private httpOptions;

  constructor(
    private http: HttpClient,
  ) {
    this.setHeader();
  }

  private setHeader(token?) {
    const header = {
      'Content-Type': 'application/json',
      'X-PARTNER': environment.partner,
      X_SYSTEM: environment.system,
      X_STEP: environment.step,
      X_SESSION: environment.session_token
    };
    this.httpOptions = { headers: new HttpHeaders(header) };
  }

  setAuthorization() {
    this.setHeader();
  }

  putAPI(endPoint, form): Observable<any> {
    return this.http.put(`${environment.endPoint}${endPoint}`, form, this.httpOptions);
  }

  postAPI(endPoint, form): Observable<any> {
    return this.http.post(`${environment.endPoint}${endPoint}`, form, this.httpOptions);
  }

  getAPI(endPoint): Observable<any> {
    return this.http.get(`${environment.endPoint}${endPoint}`, this.httpOptions);
  }

  closeToken() {
    this.setHeader();
  }
}
